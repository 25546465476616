export interface SupplementaryInvoiceSearchCriteria{
    
    refNo?: string | null,
    states?: string[] | null,
    invoiceCreditNo?: string | null,
    billToCompanies?: string[] | null,
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,
    createdDateFrom?: Date | null,
    createdDateTo?: Date | null,
    createdBys?: string[] | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA : SupplementaryInvoiceSearchCriteria = {

    refNo: null,
    states: [],
    invoiceCreditNo: null,
    billToCompanies: [],
    co: null,
    vsl: null,
    voy: null,
    opsDateFrom: null,
    opsDateTo: null,
    createdDateFrom: null,
    createdDateTo: null,
    createdBys: [],
}