export interface SupplementaryInvoiceEnabledSearchCriteria {
    all: boolean,
    refNo: boolean,
    states: boolean,
    invoiceCreditNo: boolean,
    billToCompanies: boolean,
    coVslVoy: boolean,
    opsDateRange: boolean,
    createdDateRange: boolean,
    createdBys: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA: SupplementaryInvoiceEnabledSearchCriteria = {
    all: true,
    refNo: true,
    states: true,
    invoiceCreditNo: true,
    billToCompanies: true,
    coVslVoy: true,
    opsDateRange: true,
    createdDateRange: true,
    createdBys: true
}