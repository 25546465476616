import { EMPTY_SUPPLEMENTARY_INVOICE_DETAIL_MODEL } from 'presentation/model/SupplementaryInvoice/SupplementaryInvoiceDetailModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: SupplementaryInvoiceDetailProvider,
    useTracked: useSupplementaryInvoiceDetailTracked
} = createContainer(() => useState(EMPTY_SUPPLEMENTARY_INVOICE_DETAIL_MODEL), {concurrentMode: true});
export { SupplementaryInvoiceDetailProvider, useSupplementaryInvoiceDetailTracked };

