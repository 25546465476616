
export const SupplementaryInvoiceConstant = {
    Title: {
        TITLE: "Supplementary Invoice",
        DELETE: "Delete",
        CONFIRM: "Confirm",
        UNCONFIRM: "UnConfirm",
        APPLY: "Apply",
    },
    Search: {
        SEARCH_CRITERIA: "Search Criteria",
        REF_NO: "Ref. No.",
        STATE: "State",
        INVOICE_CREDIT_NO: "Invoice/Credit No.",
        BILL_TO_COMPANY: "Bill-To Company",
        CO_VSL_VOY: "Co/Vsl/Voy",
        OPS_DATE_RANGE: "Ops Date Range",
        CREATED_DATE_RANGE: "Created Date Range",
        CREATED_BY: "Created By",
    },
    Table: {
        STATE: "State",
        REF_NO: "Ref. No.",
        OPS_DATE: "Ops Date",
        SUBJECT: "Subject",
        BILL_TO_COMPANY: "Bill-To Company",
        CUSTOMER: "Customer",
        CURRENCY: "Currency",
        CONSORTIUM_CODE: "Consortium Code",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        HANDLING_TML: "Handling Tml.",
        VESSEL_NAME: "Vessel Name",
        SERVICE: "Service",
        ETA: "ETA",
        HDR_ETD: "ETD",
        ETB: "Arr Date/Time",
        ETD: "Dep Date/Time",
        YOUR_REF: "Your Ref",
        OUR_REF: "Our Ref",
        PO_NO: "PO No.",
        LOA: "LOA",
        OPERATING_TML: 'Operating Tml',
        SOA: "SOA",
        SL_VESSEL_CODE: 'S/L Vessel Code',
        SL_IB_VOYAGE_CODE: 'S/L IB Voyage Code',
        SL_OB_VOYAGE_CODE: 'S/L OB Voyage Code',
        CHARGE_DESC: "Charge Description",
        HDR_DESC_1: "Description 1",
        HDR_DESC_2: "Description 2",
        REMARKS: "Remarks",
        INVOICE_NO: 'Invoice No.',
        DRATF_INVOICE_NO: 'Draft Invoice No.',
        CREATED_BY: "Created by",
        CREATED_DATE: "Created Date",
        MODIFIED_BY: "Modified by",
        MODIFIED_DATE: "Modified Date",
    },

    Detail: {
        DTL_STATE: "State",
        MANUAL_DETAIL_NO: "Manual Charge Detail No",
        TAR_TYPE: "Tar Type",
        TAR_CODE: "Tar Code",
        TAR_DESC: "Tar Desc",
        DETAIL_DESC_1: "Detail Desc 1",
        DETAIL_DESC_2: "Detail Desc 2",
        CHARGING_FROM_DATE: "Charging From Date",
        CHARGING_TO_DATE: "Charging To Date",
        LOG_NO: "Log No",
        ONE_OFF_RATE: "One-off Rate",
        SUR_CHARGE: "Surcharge %",
        REVENUE_AC_CODE: "Revenue A/C Code",
        COST_CENTRE_CODE: "Cost Centre Code",
        PRODUCT_SERVICE_CODE: "Product/Service Code",
        SALES_CHANNEL_CODE: "Sales Channel Code",
        COUNTRY_CODE: "Country Code",
        INTER_COMPANY_CODE: "Inter-Company Code",
        PROJECT_CODE: "Project Code",
        SPARE_CODE: "Spare Code",
        COMPANY_CODE: "Company Code",
        CHARGE_ON_COMPANY: "charge-on Company",
        INVOICE_NO: "Invoice No.",
        DRAFT_INVOICE_NO: "Draft Invoice No.",
        ADJ_TYPE: "Adj. Type",
        QTY_UOM: "Qty Uom",
        CHARGE_IND: "Charge Ind.",
        EXCEPTION_MSG: "Exception Msg",
    }
}