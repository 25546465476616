import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { SupplementaryInvoiceConstant } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useSupplementaryInvoiceDetailVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useSupplementaryInvoiceDetailTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceDetailProvider";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback } from "react";
import { HPHBreadcrumb, HPHButton } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const SupplementaryInvoiceDetailTitleBar:React.FC = () => {
    const SUPPLEMENTARY_INVOICE_CONSTANT = SupplementaryInvoiceConstant.Title;
    const [supplementaryInvoiceDetailState] = useSupplementaryInvoiceDetailTracked();
    const supplementaryInvoiceDetailVM = useSupplementaryInvoiceDetailVM();
    const {currentSuppInvoiceHeader} = supplementaryInvoiceDetailState
    const messageBarVM = useMessageBarVM();
    const [anaInfoState] = useANAInfoTracked();


    const handleApply = useCallback(() => {
        supplementaryInvoiceDetailVM.onShowLoading();
        const ids: number[] = [currentSuppInvoiceHeader.id];
        supplementaryInvoiceDetailVM.onApply(ids).then(async (data) =>{
            if(data.success){
                messageBarVM.showSuccess("Apply successfully.");
                await supplementaryInvoiceDetailVM.onSearch(currentSuppInvoiceHeader).then((data) =>{
                    supplementaryInvoiceDetailVM.onHideLoading();
                }).catch(error => {            
                    supplementaryInvoiceDetailVM.onHideLoading();
                });
            }else{
                messageBarVM.showError(data.data??'')
            }
        }).catch(error => {            
            supplementaryInvoiceDetailVM.onHideLoading();
        }).finally(() => {
            supplementaryInvoiceDetailVM.onHideLoading();
        })
    }, [currentSuppInvoiceHeader, messageBarVM, supplementaryInvoiceDetailVM]);




    const isDisable = () => {
        return supplementaryInvoiceDetailState.currentSuppInvoiceHeader.hdrState !== "NIL";
    }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderWithBackButton callback={supplementaryInvoiceDetailVM.onCloseClick}>
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[ { title: ChargeConstant.Charge.CHARGE }, { title: SUPPLEMENTARY_INVOICE_CONSTANT.TITLE}]} onCurrentClick={()=>{}} ></HPHBreadcrumb>
        </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction className="tm-animated-wrapper">
            {(anaInfoState.allowUpdate) && <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATE} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleApply} />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(SupplementaryInvoiceDetailTitleBar);