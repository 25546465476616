import { SupplementaryInvoiceDetailEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceDetailEntity"
import { EMPTY_SUPPLEMENTARY_INVOICE_ENTITY, SupplementaryInvoiceEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceEntity"
import { EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA, SupplementaryInvoiceSearchCriteria } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceSearchCriteria"
import { BaseViewChangeSate } from "../BaseViewChangeState"

export interface SupplementaryInvoiceChangeState extends BaseViewChangeSate {
    selectRows: SupplementaryInvoiceEntity[],
}

export interface SupplementaryInvoiceDetailModel {
    isLoading:boolean,
    searchCriteria: SupplementaryInvoiceSearchCriteria,
    supplementaryInvoiceState: SupplementaryInvoiceChangeState,
    currentSuppInvoiceHeader: SupplementaryInvoiceEntity,
    suppInvoiceDtls: SupplementaryInvoiceDetailEntity[],
}

export const EMPTY_SUPPLEMENTARY_INVOICE_DETAIL_MODEL: SupplementaryInvoiceDetailModel = {
    searchCriteria: { ...EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA },
    isLoading: false,
    supplementaryInvoiceState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        selectRows: []
    },
    currentSuppInvoiceHeader: { ...EMPTY_SUPPLEMENTARY_INVOICE_ENTITY },
    suppInvoiceDtls: []
}