import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { SupplementaryInvoiceDetailEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceDetailEntity";
import { SupplementaryInvoiceEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceEntity";
import { SupplementaryInvoiceSearchCriteria } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceSearchCriteria";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { SupplementaryInvoiceRepository } from "./SupplementaryInvoiceRepo";

export const SupplementaryInvoiceRepoImpl = () : SupplementaryInvoiceRepository => {
    const url = "/v1/supplementaryInvoiceSearch";

    const searchSupplementaryInvoice = async(searchCriteria: SupplementaryInvoiceSearchCriteria) : Promise<SupplementaryInvoiceEntity[]> => {
        const newUrl = url;
        
        return axiosPostData(chargeAxiosInstance,`${newUrl}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const deleteSupplementaryInvoice = async(selectRows: SupplementaryInvoiceEntity[]) : Promise<ResponseEntity> => {
        const newUrl = "/v1/deleteSupplementaryInvoice";
        try{
            return await axiosPostData(chargeAxiosInstance,`${newUrl}`, selectRows) as ResponseEntity;
        }catch(err) {
            throw err;
        };
    }
    
    const supplementaryInvoiceConfirm = async(selectRows: SupplementaryInvoiceEntity[]) : Promise<ResponseEntity> => {
        const newUrl = "/v1/supplementaryInvoiceConfirm";
        try{
            return await axiosPostData(chargeAxiosInstance,`${newUrl}`, selectRows) as ResponseEntity;
        }catch(err) {
            throw err;
        };
    }

    const supplementaryInvoiceUnConfirm = async(selectRows: SupplementaryInvoiceEntity[]) : Promise<ResponseEntity> => {
        const newUrl = "/v1/supplementaryInvoiceUnConfirm";
        try{
            return await axiosPostData(chargeAxiosInstance,`${newUrl}`, selectRows) as ResponseEntity;
        }catch(err) {
            throw err;
        };
    }

    const searchSupplementaryInvoiceDetailItems = async(hdrId: number) : Promise<SupplementaryInvoiceDetailEntity[]> => {
        const newUrl = "/v1/searchSuppInvoiceDetailItemsByManHdrId";
        
        return axiosGetData(chargeAxiosInstance,`${newUrl}/${hdrId}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const apply = async (ids: number[]) : Promise<ResponseEntity> => {
        const applyUrl = "/v1/suppInvoiceDetailsApply";
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${applyUrl}`, ids) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }
    return {
        searchSupplementaryInvoice : searchSupplementaryInvoice,
        deleteSupplementaryInvoice : deleteSupplementaryInvoice,
        supplementaryInvoiceUnConfirm : supplementaryInvoiceUnConfirm,
        supplementaryInvoiceConfirm : supplementaryInvoiceConfirm,
        searchSupplementaryInvoiceDetailItems : searchSupplementaryInvoiceDetailItems,
        apply: apply,
    }
}