import { EMPTY_SUPPLEMENTARY_INVOICE_MODEL } from 'presentation/model/SupplementaryInvoice/SupplementaryInvoiceModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: SupplementaryInvoiceProvider,
    useTracked: useSupplementaryInvoiceTracked
} = createContainer(() => useState(EMPTY_SUPPLEMENTARY_INVOICE_MODEL), {concurrentMode: true});
export { SupplementaryInvoiceProvider, useSupplementaryInvoiceTracked };

