import { SupplementaryInvoiceRepoImpl } from "domain/repository/SupplementaryInvoice/SupplementaryInvoiceRepoImpl";
import { useSupplementaryInvoiceDetailTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceDetailProvider";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { SupplementaryInvoiceDetailVM } from "presentation/viewModel/SupplementaryInvoice/SupplementaryInvoiceDetailVM";
import { useMemo } from "react";

export const useSupplementaryInvoiceDetailVM = () => {
    const [, setSupplementaryInvoiceDetailState] = useSupplementaryInvoiceDetailTracked();
    const [, setSupplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const supplementaryInvoiceDetailVM = useMemo(() =>
        SupplementaryInvoiceDetailVM({
            dispatch: [setSupplementaryInvoiceDetailState],
            hdrDispatch: [setSupplementaryInvoiceState],
            supplementaryInvoiceRepo: SupplementaryInvoiceRepoImpl(),
        }), [setSupplementaryInvoiceDetailState, setSupplementaryInvoiceState])

    return supplementaryInvoiceDetailVM
}

