import { SupplementaryInvoiceConstant } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceConstant";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { useMemo } from "react";
import { CommonField, FieldType } from "veronica-ui-component/dist/component/core";
import { SidebarTitle, Sidebarheader, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const SupplementaryInvoiceHeaderFieldPanel = () => {
    const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const { currentSuppInvoice,allFormState} = supplementaryInvoiceState;
    const SUPPLEMENTARY_INVOICE_CONSTANT = SupplementaryInvoiceConstant.Table;
    /*const supplementaryInvoiceDetailVM = useSupplementaryInvoiceDetailVM();
    const handleClose = useCallback(() => {
        supplementaryInvoiceDetailVM.onCloseClick();
    }, [supplementaryInvoiceDetailVM]);*/

    const onFieldChange = () => {
        return;
    };
    
    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSuppInvoice.hdrState || ''}
                fieldValue={currentSuppInvoice?.hdrState}
                fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.STATE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrState'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={onFieldChange} />          
        </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.STATE, allFormState, currentSuppInvoice.hdrState])

    
    const memoRefNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSuppInvoice.manChargeNo || ''}
                fieldValue={currentSuppInvoice.manChargeNo}
                fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.REF_NO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeType'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={onFieldChange} />               
        </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.REF_NO, allFormState, currentSuppInvoice.manChargeNo])

    const memoOpsDate = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
            disabled={true}
            label={SUPPLEMENTARY_INVOICE_CONSTANT.OPS_DATE}
            width="150px"
            date={currentSuppInvoice.opsDate}
            fieldName="etd"
            errorMessage={allFormState?allFormState["validateRangeDate"]:""}
            onDateChange={onFieldChange}/>
        </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.OPS_DATE, allFormState, currentSuppInvoice.opsDate])

    const memoSubject = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.subject || ''}
                    fieldValue={currentSuppInvoice.subject}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.SUBJECT}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'hdrDesc1'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />          
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.SUBJECT, allFormState, currentSuppInvoice.subject])

        const memoBillToCompany = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.billToCompany || ''}
                    fieldValue={currentSuppInvoice.billToCompany}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.BILL_TO_COMPANY}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'billToCompany'}
                    maxLength={60}
                    requiredFieldList={[]}
                    options={[]}
                    onFieldChange={onFieldChange} />    
            </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.BILL_TO_COMPANY, allFormState, currentSuppInvoice.billToCompany])
    
        const memoCustomerCode = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.customerCode || ''}
                    fieldValue={currentSuppInvoice.customerCode}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.CUSTOMER}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'customerCode'}
                    maxLength={60}
                    requiredFieldList={[]}
                    options={[]}
                    onFieldChange={onFieldChange} />    
            </div>
        , [allFormState, currentSuppInvoice.customerCode, SUPPLEMENTARY_INVOICE_CONSTANT.CUSTOMER])

        const memoCurrencyCode = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.currencyCode || ''}
                    fieldValue={currentSuppInvoice.currencyCode}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.CURRENCY}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'currencyCode'}
                    maxLength={60}
                    requiredFieldList={[]}
                    options={[]}
                    onFieldChange={onFieldChange} />    
            </div>
        , [allFormState, currentSuppInvoice.currencyCode, SUPPLEMENTARY_INVOICE_CONSTANT.CURRENCY])
    
        
        const memoConsortiumCode = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                        errorMessages={allFormState}
                        isReadOnly={true}
                        isShowOptional={true}
                        readOnlyValue={currentSuppInvoice.consortiumCode || ''}
                        fieldValue={currentSuppInvoice.consortiumCode}
                        fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.CONSORTIUM_CODE}
                        isSaveClicked={false}
                        fieldType={FieldType.TEXT}
                        fieldKey={'yourRef'}
                        maxLength={60}
                        requiredFieldList={[]}
                        onFieldChange={onFieldChange} />
            </div>
            , [SUPPLEMENTARY_INVOICE_CONSTANT.CONSORTIUM_CODE, allFormState, currentSuppInvoice.consortiumCode])
        const memoVesselCode = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                        errorMessages={allFormState}
                        isReadOnly={true}
                        isShowOptional={true}
                        readOnlyValue={currentSuppInvoice.vesselCode || ''}
                        fieldValue={currentSuppInvoice.vesselCode}
                        fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.VESSEL_CODE}
                        isSaveClicked={false}
                        fieldType={FieldType.TEXT}
                        fieldKey={'yourRef'}
                        maxLength={60}
                        requiredFieldList={[]}
                        onFieldChange={onFieldChange} />
            </div>
            , [SUPPLEMENTARY_INVOICE_CONSTANT.VESSEL_CODE, allFormState, currentSuppInvoice.vesselCode])
        const memoVoyageCode = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                        errorMessages={allFormState}
                        isReadOnly={true}
                        isShowOptional={true}
                        readOnlyValue={currentSuppInvoice.voyageCode || ''}
                        fieldValue={currentSuppInvoice.voyageCode}
                        fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.VOYAGE_CODE}
                        isSaveClicked={false}
                        fieldType={FieldType.TEXT}
                        fieldKey={'yourRef'}
                        maxLength={60}
                        requiredFieldList={[]}
                        onFieldChange={onFieldChange} />
            </div>
            , [SUPPLEMENTARY_INVOICE_CONSTANT.VOYAGE_CODE, allFormState, currentSuppInvoice.voyageCode])

        const memoHandlingTml = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                        errorMessages={allFormState}
                        isReadOnly={true}
                        isShowOptional={true}
                        readOnlyValue={currentSuppInvoice.handlingTerminal || ''}
                        fieldValue={currentSuppInvoice.handlingTerminal}
                        fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.HANDLING_TML}
                        isSaveClicked={false}
                        fieldType={FieldType.TEXT}
                        fieldKey={'yourRef'}
                        maxLength={60}
                        requiredFieldList={[]}
                        onFieldChange={onFieldChange} />
            </div>
            , [SUPPLEMENTARY_INVOICE_CONSTANT.HANDLING_TML, allFormState, currentSuppInvoice.handlingTerminal])

        const memoVesselName = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                        errorMessages={allFormState}
                        isReadOnly={true}
                        isShowOptional={true}
                        readOnlyValue={currentSuppInvoice.vesselName || ''}
                        fieldValue={currentSuppInvoice.vesselName}
                        fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.VESSEL_NAME}
                        isSaveClicked={false}
                        fieldType={FieldType.TEXT}
                        fieldKey={'yourRef'}
                        maxLength={60}
                        requiredFieldList={[]}
                        onFieldChange={onFieldChange} />
            </div>
            , [SUPPLEMENTARY_INVOICE_CONSTANT.VESSEL_NAME, allFormState, currentSuppInvoice.vesselName])
        
            const memoServiceCode = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
                <CommonField
                        errorMessages={allFormState}
                        isReadOnly={true}
                        isShowOptional={true}
                        readOnlyValue={currentSuppInvoice.serviceCode || ''}
                        fieldValue={currentSuppInvoice.serviceCode}
                        fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.SERVICE}
                        isSaveClicked={false}
                        fieldType={FieldType.TEXT}
                        fieldKey={'yourRef'}
                        maxLength={60}
                        requiredFieldList={[]}
                        onFieldChange={onFieldChange} />
            </div>
            , [SUPPLEMENTARY_INVOICE_CONSTANT.SERVICE, allFormState, currentSuppInvoice.serviceCode])

    const memoEtb = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
            disabled={true}
            label={SUPPLEMENTARY_INVOICE_CONSTANT.ETA}
            width="150px"
            date={currentSuppInvoice.etb}
            fieldName="etd"
            errorMessage={allFormState?allFormState["validateRangeDate"]:""}
            onDateChange={onFieldChange}/>
        </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.ETA, allFormState, currentSuppInvoice.etb])

    const memoEtd = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
            disabled={true}
            label={SUPPLEMENTARY_INVOICE_CONSTANT.HDR_ETD}
            width="150px"
            date={currentSuppInvoice.etd}
            fieldName="etd"
            errorMessage={allFormState?allFormState["validateRangeDate"]:""}
            onDateChange={onFieldChange}/>
        </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.HDR_ETD, allFormState, currentSuppInvoice.etd])

    
    const memoYourRef = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
        <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSuppInvoice.yourRef || ''}
                fieldValue={currentSuppInvoice.yourRef}
                fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.YOUR_REF}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'yourRef'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={onFieldChange} />
    </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.YOUR_REF, allFormState, currentSuppInvoice.yourRef])

    const memoOurRef = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
        <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSuppInvoice.ourRef || ''}
                fieldValue={currentSuppInvoice.ourRef}
                fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.OUR_REF}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'ourRef'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={onFieldChange} />
    </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.OUR_REF, allFormState, currentSuppInvoice.ourRef])

    const memoPoNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.poNo || ''}
                    fieldValue={currentSuppInvoice.poNo}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.PO_NO}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'poNo'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />   
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.PO_NO, allFormState, currentSuppInvoice.poNo])
    
        const memoLoa = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.loa?.toString() || ''}
                    fieldValue={currentSuppInvoice.loa}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.LOA}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'poNo'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />   
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.LOA, allFormState, currentSuppInvoice.loa])
    

    const memoOperatingTml = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
        <CommonField
            errorMessages={allFormState}
            isReadOnly={true}
            isShowOptional={true}
            readOnlyValue={currentSuppInvoice.operatingTml || ''}
            fieldValue={currentSuppInvoice.operatingTml}
            fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.OPERATING_TML}
            isSaveClicked={false}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'operatingTml'}
            options={[]}
            maxLength={60}
            requiredFieldList={[]}
            onFieldChange={onFieldChange} />
    </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.OPERATING_TML, allFormState, currentSuppInvoice.operatingTml])

    const memoSoa = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.soa || ''}
                    fieldValue={currentSuppInvoice.soa}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.SOA}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'poNo'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />   
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.SOA, allFormState, currentSuppInvoice.soa])

    const memoSlVesselCode = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
        <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSuppInvoice.slVesselCode || ''}
                fieldValue={currentSuppInvoice.slVesselCode}
                fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.SL_VESSEL_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'slVesselCode'}
                maxLength={6}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={onFieldChange} />  
    </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.SL_VESSEL_CODE, allFormState, currentSuppInvoice.slVesselCode])

    const memoSlIbVoyageCode = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
        <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSuppInvoice.slIbVoyageCode || ''}
                fieldValue={currentSuppInvoice.slIbVoyageCode}
                fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.SL_IB_VOYAGE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'slIbVoyageCode'}
                maxLength={10}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={onFieldChange} />  
    </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.SL_IB_VOYAGE_CODE, allFormState, currentSuppInvoice.slIbVoyageCode])

    const memoSlObVoyageCode = useMemo(() =>
    <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
        <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSuppInvoice.slObVoyageCode || ''}
                fieldValue={currentSuppInvoice.slObVoyageCode}
                fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.SL_OB_VOYAGE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'slObVoyageCode'}
                maxLength={10}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={onFieldChange} />  
    </div>
    , [SUPPLEMENTARY_INVOICE_CONSTANT.SL_OB_VOYAGE_CODE, allFormState, currentSuppInvoice?.slObVoyageCode])


    const memoChargeDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.chargeDesc || ''}
                    fieldValue={currentSuppInvoice.chargeDesc}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.CHARGE_DESC}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'hdrDesc1'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />          
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.CHARGE_DESC, allFormState, currentSuppInvoice.chargeDesc])

    const memoHdrDesc1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.hdrDesc1 || ''}
                    fieldValue={currentSuppInvoice.hdrDesc1}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.HDR_DESC_1}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'hdrDesc1'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />          
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.HDR_DESC_1, allFormState, currentSuppInvoice.hdrDesc1])
    
        const memoHdrDesc2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.hdrDesc2 || ''}
                    fieldValue={currentSuppInvoice.hdrDesc2}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.HDR_DESC_2}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'hdrDesc2'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />      
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.HDR_DESC_2, allFormState, currentSuppInvoice.hdrDesc2])
        const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                    errorMessages={allFormState}
                    isReadOnly={true}
                    isShowOptional={true}
                    readOnlyValue={currentSuppInvoice.remarks || ''}
                    fieldValue={currentSuppInvoice.remarks}
                    fieldLabel={SUPPLEMENTARY_INVOICE_CONSTANT.REMARKS}
                    isSaveClicked={false}
                    fieldType={FieldType.TEXT}
                    fieldKey={'hdrDesc2'}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={onFieldChange} />      
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.REMARKS, allFormState, currentSuppInvoice.remarks])

    return <>
            <div className='side-form-content-left-wrapper'>
                <div className={'flex-row'}>
                    <div className={'flex-row-item flex-row-item-full-width'}>
                        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
                            <Sidebarheader style={{width: '100%'}}>
                                <SidebarTitle>{currentSuppInvoice.manChargeNo}</SidebarTitle>
                                {/*<SidebarActionCross>
                                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={handleClose} />
                                </SidebarActionCross>*/}

                            </Sidebarheader>

                        </StyledSidebar>
                    </div>
                </div>

                <div className={'add-edit-form'} style={{ height:'78vh', maxHeight:'78vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                    
                    <CriteriaItemContainer>
                        {memoState}
                        {memoRefNo}
                        {memoOpsDate}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoSubject}
                        {memoBillToCompany}
                        {memoCustomerCode}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoCurrencyCode}
                        {memoConsortiumCode}
                        {memoVesselCode}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoVoyageCode}
                        {memoHandlingTml}
                        {memoVesselName}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoServiceCode}
                        {memoEtb}
                        {memoEtd}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoYourRef}
                        {memoOurRef}
                        {memoPoNo}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoLoa}
                        {memoOperatingTml}
                        {memoSoa}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoSlIbVoyageCode}
                        {memoSlObVoyageCode}
                        {memoSlVesselCode}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoChargeDesc}
                        {memoHdrDesc1}
                        {memoHdrDesc2}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoRemarks}
                    </CriteriaItemContainer>
                    </div>                  
                </div>
            </div>   
            </div>
        </>
}