import { SupplementaryInvoiceEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceEntity";
import { SupplementaryInvoiceRepository } from "domain/repository/SupplementaryInvoice/SupplementaryInvoiceRepo";
import { SupplementaryInvoiceDetailModel } from "presentation/model/SupplementaryInvoice/SupplementaryInvoiceDetailModel";
import { SupplementaryInvoiceModel } from "presentation/model/SupplementaryInvoice/SupplementaryInvoiceModel";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";

interface SupplementaryInvoiceDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<SupplementaryInvoiceDetailModel>> | ((value: SetStateAction<SupplementaryInvoiceDetailModel>) => void),
    ],
    hdrDispatch: [
        Dispatch<SetStateAction<SupplementaryInvoiceModel>> | ((value: SetStateAction<SupplementaryInvoiceModel>) => void),
    ],
    supplementaryInvoiceRepo: SupplementaryInvoiceRepository,
}

export const SupplementaryInvoiceDetailVM = ({dispatch,hdrDispatch,supplementaryInvoiceRepo,}:SupplementaryInvoiceDetailVMProps) => {
    const [supplementaryInvoiceDetailDispatch] = dispatch;
    const [supplementaryInvoiceDispatch] = hdrDispatch;



    const onShowLoading = () => {
        supplementaryInvoiceDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        supplementaryInvoiceDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedCharges = (rows:any[]) => {
        supplementaryInvoiceDetailDispatch(prevState => {
            return {
                ...prevState,
                supplementaryInvoiceState:{
                    ...prevState.supplementaryInvoiceState,
                    selectRows:rows
                },
            }
        })
    }

    const onSearch = async (currentSuppInvoiceHeader: SupplementaryInvoiceEntity) => {
        supplementaryInvoiceDetailDispatch(prevState => {
            return {
                ...prevState,
                suppInvoiceDtls: [],
            }
        })

        await supplementaryInvoiceRepo.searchSupplementaryInvoiceDetailItems(currentSuppInvoiceHeader.id).then((data) => {
            if(data){
                supplementaryInvoiceDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        suppInvoiceDtls: data,
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }


    const onApply = async (ids: number[]) => {
        return await supplementaryInvoiceRepo.apply(ids);
    }

    const onPageInit = (currentHdr: SupplementaryInvoiceEntity) => {
        supplementaryInvoiceDetailDispatch(prevState => {
            return {
                ...prevState,
                currentSuppInvoiceHeader: currentHdr,
            }
        })
    }

    const onCloseClick = () => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail: true,
                isAllowAutoSearch:true,
            }
        }); 
    }

    return {
        onHideLoading: onHideLoading,
        onShowLoading: onShowLoading,
        updateSelectedCharges: updateSelectedCharges,
        onSearch: onSearch,
        onApply: onApply,
        onPageInit: onPageInit,
        onCloseClick: onCloseClick,
    
    }
}
