import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { SupplementaryInvoiceDetailProvider } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import SupplementaryInvoiceDetailMaintenance from "presentation/view/section/SupplementaryInvoice/Detail/SupplementaryInvoiceDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const SupplementaryInvoiceDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.SUPPLEMENTARY_INVOICE_DETAIL}>
        <MessageBarWrapper>
            <SupplementaryInvoiceDetailProvider>
                <GridStyles/>
                <SupplementaryInvoiceDetailMaintenance/>
            </SupplementaryInvoiceDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default SupplementaryInvoiceDetailContMain;